<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header
                    :title="$t('courses')"
                    @filter-div-status="filterStatus = $event"/>
            </template>
            <template v-slot:header-mobile>
                <header-mobile
                    :title="$t('courses')"
                    @filter-div-status="filterStatus = $event"/>
            </template>

            <b-row class="pl-3 pb-3">
                <b-col sm="6" md="4">
                    <b-form-group :label="$t('semester_1')">
                        <semesters-selectbox :setActive="true" v-model="queryParams.semester_id"/>
                    </b-form-group>
                </b-col>
                <div class="col-12" v-if="data && data.lms_link">
                    <a :href="data.lms_link.url" target="_blank"><img style="max-height: 48px" :src="data.lms_link.logo"/></a>
                </div>
            </b-row>

            <div class="mb-5 align-items-center">
                <b-card-group deck>
                    <div v-for="(item, key) in courses" class="col-12 col-sm-6 col-md-6 col-lg-3 mb-4" :key="key">
                        <b-card bg-variant="light" :header="item.course_code" class="text-center cursor p">
                            <b-card-text>
                                <b-form-group
                                    :label="$t('course_name')">
                                    {{ item.course_name }}
                                </b-form-group>
                                <b-form-group :label="$t('section')">
                                    {{ getObjectValue(item, 'section_number') }}
                                </b-form-group>
                                <b-form-group :label="$t('instructor')">
                                    {{ getObjectValue(item, 'instructor') }}
                                </b-form-group>
                                <b-form-group :label="$t('attendance_hours')">
                                    {{ getObjectValue(item, 'attendance_hours') }}
                                </b-form-group>
                                <b-form-group :label="$t('letter_grade')">
                                    {{ getObjectValue(item, 'letter_grade') }}
                                </b-form-group>
                            </b-card-text>
                        </b-card>
                    </div>
                </b-card-group>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout.vue";
import Header from "@/layouts/AppLayout/Header.vue";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue";

import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox.vue";

import StudentProgramService from "@/services/StudentProgramService";

import qs from "qs";
import Base from "@/plugins/Base";


export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,


        SemestersSelectbox,
    },
    data() {
        return {
            courses: [],
            data: null,
            filterStatus: true,
            queryParams: {person: null, semester_id: null},
        }
    },
    created() {
        this.queryParams.person = Base.LocalStorage.get('user');

        this.getRows()

    },
    methods: {

        getRows() {
            if(!this.queryParams.semester_id){
                return;
            }
            if (this.queryParams.person && this.queryParams.person.id) {
                let config = {
                    params: {
                        semester_id: this.queryParams.semester_id
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }
                StudentProgramService
                    .getStudentCourses(config)
                    .then(res => {
                        this.setCourses(res)
                    })
                    .catch(err => this.showErrors(err))
            }
        },
        /*clearFilter() {
            this.queryParams = {};
            this.getRows(this.setCourses)
        },*/
        setCourses(res) {
            this.data = res.data.data;
            if(this.data.courses){
                this.courses = this.data.courses;
            }
            else {
                this.courses = this.data;
            }
            if (!this.queryParams.semester_id) {
                this.setActiveSemester(res)
            }
        },
        setActiveSemester(res) {
            this.data = res.data.data;
            if(this.data.courses){
                this.courses = this.data.courses;
            }
            else {
                this.courses = this.data;
            }
            if (this.courses && this.courses.length) {
                this.queryParams.semester_id = this.courses[0].semester_id
            }
        }
    },
    watch:{
        'queryParams.semester_id':function (newValue,oldValue){
            if(newValue != oldValue){
                this.getRows(this.setCourses)
            }
        }
    }
}
</script>
